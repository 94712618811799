<template>
  <div v-html="$t('ProcessLang.html')"> </div>
</template>



<script name="Process" setup>
import { onMounted } from 'vue'

onMounted(() => {
    scrollTop()
})
const scrollTop = () => {
    window.scrollTo(0, 0); // 将页面滚动到顶部
}

</script>

<style lang="scss">
.right-cell {
  font-size: 14px;

  .cell-title {
    // height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: 700;
  }

  .cell-spant {
    // height: 30px;
    line-height: 30px;
  }
}
</style>